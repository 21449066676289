import React from "react"
import Image from "../../components/image/Image"
import Whatsapp from "../../assets/contact/whatsapp.svg"
import Education from "../../assets/contact/education.svg"
import Email from "../../assets/contact/email.svg"
import Google from "../../assets/contact/google.svg"

const Cv = () => {
  return (
    <div className="cv">
      <div className="container">
        <h1 className="heading bg-title">About Me</h1>
        <p>
          As a Full Stack Developer, I handle both the front-end and back-end
          web projects to create seamless and engaging user experiences. My
          focus is on developing effective, user-friendly solutions that achieve
          great results. I blend technical expertise with a knack for
          problem-solving to deliver innovative applications.
        </p>

        <div>
          <div className="contact">
            <Image src={Education} height={25} width={25} alt="MCS" />
            <span className="txt-light-green">
              Masters of Computer Sciences<sup> 2018</sup>
            </span>
          </div>

          <div className="contact">
            <Image src={Whatsapp} height={23} width={23} alt="WA" />
            <span className="txt-light-green">+92 332 465 9119</span>
          </div>

          <div className="contact email">
            <Image src={Google} height={27} width={27} alt="Email" />
            <span className="txt-light-green">naseer4uplus@gmail.com</span>
          </div>

          <div className="contact email">
            <Image src={Email} height={30} width={30} alt="Email" />
            <span className="txt-light-green">contact@naseer.one</span>
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className="heading bg-title">My Services</h1>
        <ul>
          <li className="txt-light-green">* Web Development (with)</li>
          <ul>
            <li>- Wordpress</li>
            <li>- React.js</li>
            <li>- Node.js</li>
            <li>- Express.js</li>
            <li>- MongoDB</li>
          </ul>

          <li className="txt-light-green">* Mobile App Development (with)</li>
          <ul>
            <li>- Bare React Native</li>
            <li>- React Native Expo</li>
          </ul>
          <li className="txt-light-green">* UI / UX Design (with)</li>
          <ul>
            <li>- Adobe XD</li>
            <li>- Figma</li>
          </ul>
          <li className="txt-light-green">* Graphics Designing (with)</li>
          <ul>
            <li>- Adobe Illustrator</li>
            <li>- Adobe Photoshop</li>
            <li>- Affinity Designer</li>
          </ul>
          <li className="txt-light-green">* IT Support</li>
        </ul>
      </div>

      
      <div className="container">
        <h1 className="heading bg-title">Worked with Technologies</h1>

        <div className="skills-container">
          <div>Javascript</div>
          <div>Typescript</div>
          <div>Reactjs</div>
          <div>Nextjs</div>
          <div>React-Native</div>
          <div>Nodejs</div>
          <div>Express</div>
          <div>Objectionjs</div>
          <div>knexjs</div>
          <div>GraphQL</div>
          <div>Git</div>
          <div>Github</div>
          <div>Gitlab</div>
          <div>Docker</div>
          <div>Html5</div>
          <div>CSS3</div>
          <div>jQuery</div>
          <div>PHP</div>
          <div>CodeIgniter</div>
          <div>WordPress</div>
          <div>WP Theme Customization</div>
          <div>Shopify</div>
          <div>Strapi</div>
          <div>MS Excel</div>
          <div>MS Word</div>
          <div>VBA</div>
          <div>Peachtree</div>
          <div>Adobe Illustrator</div>
          <div>Adobe Photoshop</div>
          <div>Adobe XD</div>
          <div>Figma</div>
          <div>Affinity Designer</div>
          <div>MySQL</div>
          <div>SQL Server</div>
          <div>MongoDB</div>
          <div>DynamoDB</div>
          <div>PostgreSQL</div>
          <div>Firebase</div>
          <div>SQLite</div>
          <div>AWS Cognito</div>
          <div>OAuth</div>
          <div>AWS Lightsail</div>
          <div>AWS Lambda</div>
          <div>EC2</div>
          <div>Nginx</div>
          <div>Apache</div>
          <div>S3 Bucket</div>
          <div>Firestore</div>
          <div>MaterialUI</div>
          <div>Bootstrap</div>
          <div>Native Base</div>
          <div>MaterializeCSS</div>
          <div>TailwindCSS</div>
          <div>AntDesign</div>
          <div>Kafka</div>
          <div>Adminer</div>
          <div>RESTful APIs</div>
          <div>Kubernetes</div>
          <div>Ubuntu</div>
          <div>Linux Mint</div>
          <div>Windows</div>
          <div>VSCode</div>
          <div>Sublime Text</div>
        </div>
      </div>
    </div>
  )
}

export default Cv
