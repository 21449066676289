import React,{useState} from "react"
import "./assets/css/styles.css"
import Nav from "./components/nav/Nav"
import Cli from "./components/cli/Cli"
import Cv from "./pages/cv/Cv"
import Git from "./pages/git/git"
import Docker from "./pages/docker/docker"
import Javascript from "./pages/js/Javascript"
import TypingMaster from "./pages/typingmaster/typingmaster"
import { appConstant } from "./constant/constant"
function App() {

  const {CV,TODO,TYPINGMASTER,GIT,DOCKER,JAVASCRIPT} = appConstant.PAGES;
  const [showPage,setShowPage] = useState(CV)

  const handlePage = (page)=>{
    setShowPage(page);
  }

  const renderPage = ()=>{
    switch(showPage){
      case CV:
        return <Cv />
      case DOCKER:
        return <Docker />
      case GIT:
        return <Git/>
      case JAVASCRIPT:
         return <Javascript/>  
      case TYPINGMASTER:
          return <TypingMaster/>     
      default:
        return <div></div>    
    }
  }
  return (
    <div className="bg-body">
      <Nav/>
      {<Cli handlePage={(page)=>handlePage(page)}/>}
      {renderPage()}
    </div>
  );
}

export default App;
