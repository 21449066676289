export const appConstant = {
    PAGES:{
        CV:'CV',
        DOCKER:'Docker',
        GIT:'Git',
        JAVASCRIPT:'Javascript',
        TYPINGMASTER:'Typing Master',
        TODO:'Todo'
    },
    SUBMENUS:{
        CHEATSHEET:'Cheat Sheet'
    }
}