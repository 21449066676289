"use client"
import menu from "../nav/MenuItems";
import useCliLogic from "./useCliLogic";

const Cli = (props) => {
  const {handlePage }= props
  const {
    showResult,
    mainMenu,
    inputRef,
    btnRef,
    handleCmdEnter,
    hideResult,
    handleCommand,
    handleSubMenu,
    showError,
    setShowError
  } = useCliLogic(handlePage)

  const result = <div className="result-cli">
    <div className="result-container">
      {mainMenu.show && <div>⾕</div>}
      {!mainMenu.show && <div>  ☰  </div>}
      <div className="result-close" onClick={()=>hideResult()}>[ ✖ ]</div>
      </div>
    <div>
      {
        mainMenu.show && <div className="main-menu">
            
              {menu.map((item,ind)=>(<div key={ind} className="cli-command-item" onClick={()=>handleCommand(item.name,ind)}>{item.name}</div>))}
        </div>
      }

{
        (!mainMenu.show) && <div className="main-menu">
              <div className="cli-command-item" onClick={()=>handleSubMenu("back")}>↵--</div>
              {menu[mainMenu.index].values.map((item,ind)=>(<div key={ind} className="cli-command-item" onClick={()=>handleSubMenu(item)}>{item}</div>))}
        </div>
      }
      
    </div>
  </div>

  const invalid = <div className="error-container">
    <div className="error-container-bg"></div>
    <div className="error-msg container" >
      <div className="error-border">
        <p className="error-msg-title"> Error! </p>
      <p className="error-msg-txt">You have entered invalid command. Please type "ls".</p>
      <div className="error-btn-container">
        <div></div>
        <button className="btn" ref={btnRef} onClick={()=>setShowError(false)}>[ <u >O</u>K ]</button>
        <div></div>
      </div>
      
      </div>
     
    </div>
    </div>
  return (
    <div className="container">
      {showResult && result}
      {showError && invalid}
      <div className="cmd">
        
          <label>root@naseer.one:~$</label>
          <input
            ref={inputRef} 
            type="text"
            id="cmd"
            onKeyDown={(event) => handleCmdEnter(event)}
          />
        
      </div>
    </div>
  )
}

export default Cli
