import React,{useState,useEffect} from 'react'
import MyCodeComponent from '../../components/mycodecomponent/MyCodeComponent'
const Docker = () => {
    const [fileContent, setFileContent] = useState('')

    // Fetch the content of code.txt after the component mounts
    useEffect(() => {
      fetch('/code/docker.txt')
        .then((response) => response.text()) // Read as text
        .then((text) => setFileContent(text)) // Set the file content in state
        .catch((error) => console.error('Error fetching file:', error))
    }, [])
  return (
    <div className="container">
        <h1>Docker</h1>
        <MyCodeComponent code={fileContent} lang={"bash"} />
    </div>
  )
}

export default Docker