import {useState,useRef,useEffect} from "react";
import menu from "../nav/MenuItems";
import { appConstant } from "../../constant/constant";
import commands from "./commands";

const {CV} = appConstant.PAGES
const {LS} = commands
const useCliLogic = (handlePage) => {

  const [showResult, setShowResult] = useState(false)
  const [mainMenu,setMainMenu] = useState({show:true,name:'',index:null})
  const inputRef = useRef()
  const btnRef = useRef(null)
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    // Apply or remove the 'no-scroll' class based on showError
    if (showError) {
      document.body.classList.add('no-scroll');
      setTimeout(()=>btnRef.current.focus(),10)
      
    } else {
      document.body.classList.remove('no-scroll');
      inputRef.current.focus();
    }
  }, [showError]);  


  const handleCmdEnter = (event) => {
    if (event.key === "Enter") {
      const command = inputRef.current.value;
      switch(command){
        case LS:
          setShowResult(true)
          handlePage(false)
          break;
         default:
          setShowError(true)


      }
      inputRef.current.value = ""
    }
  }

  const hideResult = ()=>{
    inputRef.current.value = ""
    setShowResult(false);
    handlePage(CV)
    setMainMenu((prev)=>({show:true}))
  }

  const handleCommand = (cmd,index)=>{
    console.log(cmd,index)
    if(menu[index].hasOwnProperty('values')){
      setMainMenu((prev)=>({show:false,name:cmd,index:index}))
      console.log('has sub menu')
    }else{
      executeCommand(cmd);
    }
  }

  const handleSubMenu = (cmd)=>{
    if(cmd === "back"){
      setMainMenu((prev)=>({show:true,name:'',index:null}))
    }else{
      executeCommand(cmd);
    }
  }

  const executeCommand = (cmd)=>{
    console.log('executing command',cmd)
    handlePage(cmd)
  }

  return{
    showResult,
    mainMenu,
    inputRef,
    btnRef,
    handleCmdEnter,
    hideResult,
    handleCommand,
    handleSubMenu,
    showError,
    setShowError


  }
  
}

export default useCliLogic